type ExponentialBackoffOptions = {
	minDelay: number;
	maxDelay: number;
	maxAttempts?: number;
	factor?: number;
};

export class ExponentialBackoff {
	private attempts = 0;
	private readonly factor: number;
	private readonly maxAttempts: number;

	constructor(private readonly options: ExponentialBackoffOptions) {
		this.factor = options.factor || 2;
		this.maxAttempts = options.maxAttempts || Number.POSITIVE_INFINITY;
	}

	next(): number {
		this.attempts++;
		const delay = Math.min(this.options.minDelay * this.factor ** (this.attempts - 1), this.options.maxDelay);
		return delay;
	}

	getCurrentAttempts(): number {
		return this.attempts;
	}

	getMaxAttempts(): number {
		return this.maxAttempts;
	}

	reset(): void {
		this.attempts = 0;
	}
}
