import type {FieldValues, Path, UseFormReturn} from 'react-hook-form';
import {type APIErrorCode, APIErrorCodes} from '~/Constants';
import type {HttpResponse} from '~/lib/HttpClient';

export const handleError = <T extends FieldValues>(
	form: UseFormReturn<T>,
	error: HttpResponse,
	defaultPath: Path<T>,
) => {
	if (error.body && typeof error.body === 'object') {
		if (((error.body as any).code as APIErrorCode) === APIErrorCodes.INPUT_VALIDATION_ERROR) {
			const errors = (error.body as any).errors;
			for (const error of errors) {
				const path = error.path as Path<T>;
				const message = error.message;
				form.setError(path, {type: 'server', message});
			}
		} else {
			form.setError(defaultPath, {type: 'server', message: (error.body as any).message});
		}
	}
};
